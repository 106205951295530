import firebase from "firebase/app"

const config = {
  apiKey: "AIzaSyCRTWwyFfVK8phS2vANDWBvJP8vqITu0Js",
  //authDomain: "prabodhtechnologies-webapp.firebaseapp.com",
  authDomain: "auth.prabodh.in",
  databaseURL: "https://prabodhtechnologies-webapp.firebaseio.com",
  projectId: "prabodhtechnologies-webapp",
  storageBucket: "prabodhtechnologies-webapp.appspot.com",
  messagingSenderId: "123398771273",
  appId: "1:123398771273:web:3afe29de96a23cb7d42598",
  measurementId: "G-R40CL683DN"
};


var firebaseApp = null;

let getFireBaseApp = function () {
  if (firebaseApp == null) {
    firebaseApp = firebase.initializeApp(config);
  }
  return firebaseApp;
}

export default getFireBaseApp;