import { Anchor, Box, Button, Collapsible, Footer, Heading, Grommet, Layer, ResponsiveContext, Markdown, Text } from "grommet";
import { FormClose, Menu, Login, Logout } from 'grommet-icons';

import withFirebaseAuth from 'react-with-firebase-auth'
import firebase from "firebase/app"
import "firebase/auth"

import getFirebaseApp from './firebaseConfig';
import axios from 'axios';

import React, { useState } from "react";
import YouTube from 'react-youtube';


const theme = {
  "name": "prabodhTheme",
  "rounding": 4,
  "spacing": 24,
  "defaultMode": "light",
  "global": {
    "colors": {
      "brand": {
        //"dark": "#-bcaaa4--",
        "dark": "#1d90cb",
        "light": "#1d90cb"
      },
      "background": {
        "dark": "#111111",
        "light": "#FFFFFF"
      },
      "background-back": {
        "dark": "#111111",
        "light": "#EEEEEE"
      },
      "background-front": {
        "dark": "#222222",
        "light": "#FFFFFF"
      },
      "background-contrast": {
        "dark": "#FFFFFF11",
        "light": "#11111111"
      },
      "text": {
        "dark": "#EEEEEE",
        "light": "#333333"
      },
      "text-strong": {
        "dark": "#FFFFFF",
        "light": "#000000"
      },
      "text-weak": {
        "dark": "#CCCCCC",
        "light": "#444444"
      },
      "text-xweak": {
        "dark": "#999999",
        "light": "#666666"
      },
      "border": {
        "dark": "#444444",
        "light": "#CCCCCC"
      },
      "control": "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning"
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    "active": {
      "background": "active-background",
      "color": "active-text"
    },
    "hover": {
      "background": "active-background",
      "color": "active-text"
    },
    "selected": {
      "background": "selected-background",
      "color": "selected-text"
    }
  },
  "chart": {},
  "diagram": {
    "line": {}
  },
  "meter": {},
  "layer": {
    "background": {
      "dark": "#111111",
      "light": "#FFFFFF"
    }
  }
};


const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
);


const firebaseApp = getFirebaseApp();

const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const App = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const {
    user,
    signOut,
    signInWithGoogle,
  } = props;

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      //  
      autoplay: 0,
    },
  };

  return (
    <Grommet theme={theme} themeMode={theme.defaultMode} full>
      <ResponsiveContext.Consumer>
        {size => (
          <Box fill>
            <AppBar>
              <Box direction='row' align='center' justify='center'>
                <Button icon={<Menu />} disabled={!user} onClick={() => setShowSidebar(!showSidebar)} />
                <Box>
                  <Heading level='3' margin='none'>Prabodh Technologies</Heading>
                  <Text size='xsmall'>
                    A place where <Markdown>*Ideas*</Markdown> meet <Markdown>*Expertise*</Markdown>
                  </Text>
                </Box>
              </Box>
              {
                //tip={{content: "Login", plain:true, dropProps: {margin:"20px 0"}}}
                (!user
                  ?
                  <Button icon={<Login />} plain={true} label={size !== 'small' ? "Login" : ""} onClick={() => {
                    signInWithGoogle().then((result) => {
                      //var loggedInUser = result.user;
                      //console.log("" + loggedInUser?.displayName);
                      return result.user?.getIdToken();
                    }, (error) => {
                      //var email = error.email;
                      //var credential = error.credential;
                      console.log(error);
                      if (error.code === 'auth/account-exists-with-different-credential') {
                        console.log('account-exists-with-different-credential');
                      }
                    }).then((idToken) => {
                      console.log('about to post');
                      axios.get("http://localhost:8080/users").then(data => {
                        console.log(data);
                      })

                      /*
                                            fetch('http://localhost:8080/claims/setCustomClaims', {
                                              method: 'POST',
                                              mode: 'no-cors',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'authorization': 'Bearer ' +
                                                 JSON.stringify(idToken)
                                              },
                                              body: JSON.stringify({
                                                idToken: idToken
                                              })
                                            })
                                              .then(res => res.json())
                                              .then((data, status) => {
                                                // enter you logic when the fetch is successful
                                                if (status == 'success' && data) {
                                                  const json = JSON.parse(data);
                                                  if (json && json.status == 'success') {
                                                    // Force token refresh. The token claims will contain the additional claims.
                                                    firebase.auth().currentUser.getIdToken(true);
                                                  }
                                                }
                                              })
                                              .catch(error => {
                                                // enter your logic for when there is an error (ex. error toast)
                                                console.log(error)
                                              })
                      
                      */
                    }); //.then close braces
                  }} />
                  :
                  <Button icon={<Logout />} plain={true} label={size !== 'small' ? "Logout" : ""} onClick={() => {
                    //setUserLoggedIn(false)
                    signOut();
                    if (showSidebar) {
                      setShowSidebar(false);
                    }
                  }} />
                )
              }
            </AppBar>
            <Box flex direction='row' overflow={{ horizontal: 'hidden' }}>
              {(!showSidebar || size !== 'small') ? (
                <Collapsible direction="horizontal" open={showSidebar}>
                  <Box
                    flex
                    width='small'
                    background='light-2'
                    elevation='small'
                    align='center'
                    justify='start'
                  >
                    <Text style={{ 'margin-top': '30px' }}>sidebar1</Text>
                    <Text>sidebar2</Text>
                    <Text>sidebar3</Text>
                  </Box>
                </Collapsible>
              ) : (
                <Layer>
                  <Box
                    background='light-2'
                    tag='header'
                    align='center'
                    justify='between'
                    direction='row'
                  >
                    sidebar
                    <Button
                      icon={<FormClose />}
                      onClick={() => setShowSidebar(false)}
                    />
                  </Box>
                </Layer>
              )}
              <Box flex align='center' justify='center'>

                <YouTube videoId="R2jAL2bt9xg" opts={opts} onEnd={
                  (event) => {
                    window.location.reload();
                  }
                }/>
                {
                  //<Text size="xlarge">Please visit after few days .... {user ? user.displayName : ""}</Text>
                }
              </Box>
            </Box>
            <Footer >
              <Box flex direction="row" align="center" justify="center">
                <Text size="small">Copyright: 2021 Prabodh Technologies LLP. All Rights Reserved.</Text>
                <Anchor size="small" margin="medium" color="brand" weight="normal" label="Privacy Policy" />
                <Anchor size="small" margin="small" color="brand" weight="normal" label="Disclaimer" />
              </Box>
            </Footer>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
